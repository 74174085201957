import { 
  cacheExchange,
  fetchExchange,
  createClient,
} from '@urql/core'

import { authExchange } from '@urql/exchange-auth'
import { GraphQLError } from "graphql"
import { firebaseTools } from './firebase'
import { state } from './localStorage'
import { useAuthStore } from '@/stores'

import * as Sentry from "@sentry/vue"

const UNAUTHENTICATED_CODE = "validation-failed"

const getToken = async () => {
  if (!!state.value.jwtRefresh && new Date(state.value.jwtRefresh).getTime() > Date.now()) {
    return { token: state.value.jwt }
  } else {
    const {token:newToken, jwtRefresh} = await firebaseTools.getCurrentUserJWT(true) || ''
    state.value.jwt = newToken
    state.value.jwtRefresh = jwtRefresh
    if (newToken !== '') {
      return { token: newToken }
    } else {
      state.value.jwt = ''
      state.value.jwtRefresh = ''
      return { token: null }
    }
  }
}

const willAuthError = () => {
  // e.g. check for expiration, existence of auth etc\
  if (!state.value.jwt || state.value.jwt === '') return true;
  const timeLeft = new Date(state.value.jwtRefresh).getTime() - new Date().getTime()
  if (timeLeft > 0) return false;
  return true;
}

const didAuthError = (error: any) => {
  console.info(error)
	return error.graphQLErrors.some(
		(e: GraphQLError) => e.extensions?.code === UNAUTHENTICATED_CODE 
	)
}

export const urqlConfig: any = {
  url: 'https://rutasegura.stellate.sh',
  fetchOptions: {
    headers: {
      'content-type': 'application/json',
    },
  },
  requestPolicy: 'cache-and-network',
  exchanges: [
    cacheExchange,
    authExchange(async utils => {
      return {
        addAuthToOperation(operation) {
          if (!state.value.jwt || state.value.jwt === '') return operation;
          const {account} = useAuthStore()
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${state.value.jwt}`,
            'user-id': account?.id || '',
          })
        },
        didAuthError,
        willAuthError,
        refreshAuth: async () => {
          try {
            await getToken()
          } catch (error) {
            // TODO: send to logout page
            Sentry.captureException(error)
          }
        },
      };
    }),
    fetchExchange,
  ],
}

export const client = createClient(urqlConfig)